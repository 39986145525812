.echart-financial-Activities {
  height: 27.8rem;
  width: 100%;
  @include media-breakpoint-up(xxl) {
    height: 46rem;
  }
}
.flight-desc-card {
  width: auto;
  position: absolute;
  bottom: map-get($spacers, 3);
  left: 50%;
  transform: translateX(-50%);
  left: 50%;
  width: calc(100% - map-get($spacers, 5));
  @include media-breakpoint-up(sm) {
    max-width: 31.25rem !important;
  }
  @include media-breakpoint-up(md) {
    bottom: map-get($spacers, 8);
  }
  .progress {
    position: relative;
    border-top: 1px dotted var(--#{$prefix}info);
    background: transparent;
    overflow: visible;
    height: 0;
    border-radius: 0;
    .progress-bar {
      position: absolute;
      top: 50%;
      height: 2px;
      transform: translateY(-75%);
    }
  }
}

.marker-1 {
  background-image: url('../img/icons/fly-map-marker.png');
  background-size: 100%;
  width: 1.875rem;
  height: 2.94rem;
  top: -1.125rem;
  [data-bs-theme='dark'] &{
    background-image: url('../img/icons/fly-map-marker_dark.png');
  }
}
.marker-2 {
  background-image: url('../img/icons/plane.png');
  background-size: 100%;
  width: 2.125rem;
  height: 2.125rem;
  [data-bs-theme='dark'] &{
    background-image: url('../img/icons/plane_dark.png');
  }
}
.marker-3 {
  background-image: url('../img/icons/land-map-marker.png');
  background-size: 100%;
  width: 1.875rem;
  height: 2.94rem;
  top: -1.125rem;
  [data-bs-theme='dark'] & {
    background-image: url('../img/icons/land-map-marker_dark.png');
  }
}

// add property
.price-tier-form {
  .pricings {
    display: none;
  }
  &.active {
    background: var(--#{$prefix}emphasis-bg);
    .pricings {
      display: block !important;
    }
    .form-switch {
      label {
        color: var(--#{$prefix}primary-darker) !important;
      }
    }
  }
}
.age-segment-input {
  @include media-breakpoint-up(sm) {
    max-width: 5.75rem;
  }
}
.flex-sm-1 {
  @include media-breakpoint-up(sm) {
    flex: 1;
  }
}
