// @import 'swiper/css/bundle';
.swiper-theme-container {
  position: relative;
  .swiper-wrapper {
    &.swiper-continuous-autoplay {
      transition-timing-function: linear;
    }
    .swiper-slide {
      height: auto !important;
    }
  }
  .swiper-nav {
    margin-top: 0;
    cursor: pointer;
    [class*='swiper-'] {
      position: absolute;
      top: 50%;
      border: 1px solid;
      border-color: var(--#{$prefix}border-color-translucent);
      transition: opacity 0.4s ease-in-out;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      color: var(--#{$prefix}body-color);
      background-color: var(--#{$prefix}body-bg) !important;
      box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.12);
      z-index: 1;
      margin: 0;
      @include hover-focus {
        background-color: var(--#{$prefix}emphasis-bg);
        color: var(--#{$prefix}tertiary-color);
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      svg {
        width: 50%;
        height: 50%;
      }
      &::after,
      &::before {
        content: '';
      }
      &.swiper-button-disabled {
        opacity: 0;
      }
    }
    .swiper-button-prev {
      left: -1rem;
    }
    .swiper-button-next {
      right: -1rem;
      [dir='rtl'] & {
        left: calc(100% - 1rem);
      }
    }
  }
  &.swiper-slide-nav-top{
    .swiper-button-next,
    .swiper-button-prev{
      left: 50%;
      top: -60px;
      transform: translateX(-50%);
    }
    .swiper-button-next{
      margin-left: 1.25rem;
      [dir='rtl'] & {
        left: 50%;
      }
    }
    .swiper-button-prev{
      margin-left: -1.25rem;
    }
  }
}